import React from 'react';
import { Link, graphql } from 'gatsby'

import Header from '../components/header'
import '../components/video.css'
import ReactPlayer from 'react-player'
import Layout from '../layouts/index.js'
import styled from 'styled-components'

export const query = graphql`
  query BlogList($limit: Int!, $cursor: String) {
    prismic {
        _allDocuments(sortBy: meta_firstPublicationDate_DESC, first: $limit, after: $cursor) {
            edges {
                node {
                    ... on PRISMIC_Movie {
                    link
                    _linkType
                    _meta {
                        id
                        tags
                    }
                    }
                }
            }
        }
    }
  }
`

class MovieList extends React.Component {
    render(){
        const { currentPage, numPages } = this.props.pageContext
        const isFirst = currentPage === 1
        const isLast = currentPage === numPages
        const prevPage = currentPage - 1 === 1 ? "/" : `/${(currentPage - 1).toString()}/`
        const nextPage = `/${(currentPage + 1).toString()}/`
        return (
            <Layout>
                <SiteContainer>
                    <Header></Header>
                    <VideoGrid>
                    {this.props.data && this.props.data.prismic && this.props.data.prismic._allDocuments.edges.map((movie, i) => (
                        <Video id={movie.node._meta.tags[0]} key={movie.node._meta.id + i}>
                            <div className='video-wrapper' key={movie.node._meta.id + i}>
                                <div className='player-wrapper' key={movie.node._meta.id + i}>
                                    <ReactPlayer
                                        config={{ 
                                            youtube: { 
                                                embedOptions: { 
                                                    host: 'https://www.youtube-nocookie.com' 
                                                } 
                                            } 
                                        }} 
                                        url={movie.node.link.provider_name === "Vimeo" ? "https://player.vimeo.com/video/" + movie.node.link.video_id + "?dnt=true" : movie.node.link.embed_url}
                                        light={movie.node.link.provider_name === "Vimeo" ? movie.node.link.thumbnail_url.split('_')[0] : movie.node.link.thumbnail_url} 
                                        width='100%' 
                                        height='100%'
                                        className='react-player'
                                        playing={true}
                                        controls={true}
                                        key={movie.node._meta.id}
                                        
                                    />
                                </div>
                                <VideoDetails>
                                    <Title key={movie.node._meta.id}>{movie.node.link.title}</Title>
                                    {/* <Source href={movie.node.link.embed_url} target="_blank">Source</Source> */}
                                </VideoDetails>
                                
                            </div>
                        </Video>
                        ))}
                    </VideoGrid>
                    <Pagination>
                        <PaginationButtonContainer>
                            {!isFirst && (
                                <PaginationButton to={prevPage} rel="prev"  className="back">
                                ← Previous Page
                                </PaginationButton>
                            )}
                        </PaginationButtonContainer>
                        <PaginationButtonContainer>
                            {!isLast && (
                                <PaginationButton to={nextPage} rel="next" className="forward">
                                Next Page →
                                </PaginationButton>
                            )}
                        </PaginationButtonContainer>
                    </Pagination>
                    <Header bottom></Header>
                </SiteContainer>
            </Layout> 
        )
    }
}

export default MovieList

const SiteContainer = styled.div`
    width:100%;
`;


const VideoGrid = styled.div`
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    grid-gap: var(--borderSize);
    background-color: var(--dark);
    margin-top: var(--borderSize);

    @media only screen and (min-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
        justify-items: stretch;
    }

    @media only screen and (max-width: 350px) {
        grid-template-columns: repeat(2, 1fr);
        justify-items: stretch;
        display: block;
        border-bottom: var(--borderSize) solid var(--dark);
    }

`;

const Pagination = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    border-top: var(--borderSize) solid var(--dark);

    a {
        color: #333333;
        text-decoration: none;
        font-family: Inter Bold;
    }

`

const PaginationButtonContainer = styled.div`
    width: 100%;
    padding: 0 0;
`

const PaginationButton = styled(Link)`
    width: 100%;
    padding: 60px 0;
    text-align: center;
    display: inline-block;
    transition: .3s;

    :hover{
        background-color: var(--dark);
        color: var(--light);
    }

`

const Title = styled.h3`
    font-family: Inter Bold;
    font-size: 30px;
    margin: 0;

    @media only screen and (max-width: 900px) {
        font-size: 24px;
    }

    @media only screen and (max-width: 350px) {
        font-size: 18px;
    }

`

const VideoDetails = styled.div`
  /* width: 100%; */
`;


const Video = styled.div`
    /* border-bottom: var(--borderSize) solid var(--dark); */
    padding: 30px;
    background-color: var(--light);

    @media only screen and (max-width: 350px) {
        border-bottom: var(--borderSize) solid var(--dark);
    }

    @media only screen and (min-width: 900px) {
        
        /* grid-column: ${props => props.Tag === 'featured' ? '1 / span 2' : '0'};
        display: ${props => props.Tag === 'featured' ? 'grid' : 'block'}; */


        :first-child,
        :nth-child(6){
            grid-column: 1 / span 2;
            display: grid;
        }

        :first-child .video-wrapper ${Title},
        :nth-child(6) .video-wrapper ${Title} {
            font-family: Inter Bold;
            font-size: var(--bigText);
            margin: 0;
            display: block;

        }
        
        :first-child .video-wrapper,
        :nth-child(6) .video-wrapper{
            display: grid;
            grid-template-columns: repeat(12, 2fr);
            align-items: flex-start;
            grid-auto-flow: dense;
        }
    }

    @media only screen and (min-width: 1201px) {
        
        :first-child .video-wrapper .player-wrapper,
        :nth-child(6) .video-wrapper .player-wrapper{
            grid-column: 5 / span 8;
        }

        :first-child ${VideoDetails},
        :nth-child(6) ${VideoDetails} {
            padding: 30px;
            grid-column: 1 / span 4;
        }
    }
        
    @media only screen and (min-width: 900px) and (max-width: 1200px) {
        
        :first-child .video-wrapper .player-wrapper,
        :nth-child(6) .video-wrapper .player-wrapper{
            grid-column: 6 / span 7;
        }

        :first-child ${VideoDetails},
        :nth-child(6) ${VideoDetails} {
            padding: 30px;
            grid-column: 1 / span 5;
        }
    }
`;