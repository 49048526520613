import { createGlobalStyle } from 'styled-components';

import InterRegular from '../../assets/fonts/Inter-Regular.woff2';
import InterRegular2 from '../../assets/fonts/Inter-Regular.woff';
import InterBold from '../../assets/fonts/Inter-Bold.woff2';
import InterBold2 from '../../assets/fonts/Inter-Bold.woff';


const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: Inter;
        src: url(${InterRegular}), url(${InterRegular2});
    }

    @font-face {
        font-family: Inter Bold;
        src: url(${InterBold}), url(${InterBold2});
    }
    :root {
        --dark: #333333;
        --light: #FFFFF8;
    }
    * {
        margin: 0;
        padding: 0;
    }

    html {
        --borderSize: 4px;
        --bigText: 42px;

        @media(min-width: 1400px){
            --bigText: 50px
        }
        @media(min-width: 901px) and (max-width: 1499px) {
            --bigText: 35px
        }
        @media(min-width: 801px) and (max-width: 900px) {
            --bigText: 28px
        }
        @media(max-width: 800px) {
            --borderSize: 2px;
        }
    }

    body {
        font-family: 'Inter', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 18px;
        line-height: 1.3;
        background-color: var(--light);
        margin: 10px;
        border-top: var(--borderSize) solid var(--dark);
        border-right: var(--borderSize) solid var(--dark);
        border-left: var(--borderSize) solid var(--dark);

        @media only screen and (max-width: 900px) {
            margin: 5px;
        }

    }
`;

export default GlobalStyles;