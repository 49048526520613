import React from 'react'
import PropTypes from 'prop-types'
// import './index.css'
import GlobalStyles from '../components/styles/GlobalStyles';
import Helmet from "react-helmet"
import Banner from '../components/banner';
// import SEO from '../components/seo'

const Layout = ({ children }) => (
  <div>
    <GlobalStyles />
    <Helmet
      title="Designmovies"
      meta={[
        {
          name: `description`,
          content: "Design talks and Inspiration",
        },
        {
          property: `og:title`,
          content: "Designmovies",
        },
        {
          property: `og:description`,
          content: "Design talks and Inspiration",
        },
        {
          property: `og:image`,
          content: "/images/social.png",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `A collection of videos design talks and inspiration`,
        },
        {
          name: `twitter:creator`,
          content: "@kaandk",
        },
        {
          name: `twitter:title`,
          content: "Designmovies",
        },
        {
          name: `twitter:description`,
          content: "Design talks and Inspiration",
        },
      ]}
    />
    <Banner></Banner>
    {children}
  </div>
)

Layout.propTypes = {
  children: PropTypes.object,
}

export default Layout