import React from 'react';
import styled from 'styled-components'

class Banner extends React.Component {
    state = { showing: true };
    render(){
        const { showing } = this.state;
        return (
            <Cookie onClick={() => this.setState({ showing: !showing })} style={{ display: (showing ? 'grid' : 'none') }}>
                <p>This website doesn't use cookies. If you click on a video it will load cookies from Youtube and/or Vimeo. If you don't want cookies, please don't click a video.</p>
                <div className="close">ⓧ</div>
            </Cookie>
        )
    }
}

export default Banner



const Cookie = styled.div`
  grid-template-columns: repeat(8, 2fr);
  width:400px;
  position: fixed;
  bottom: 30px;
  left: 30px;
  background-color: #fff;
  border: 1px solid var(--dark);
  z-index: 100;
  padding: 20px;
  font-size: 14px;
  line-height: 1.5;
  box-shadow: 5px 5px rgba(0,0,0,.25);
  column-gap: 24px;
  cursor: pointer;
  display: grid;

  @media(max-width: 800px) {
    width: calc(100% - 80px);
    left: 20px;
    bottom: 20px;
  }

  p {
    grid-column: 1 / span 8;
  }

  .close {
    grid-column: 9 / span 1;
  }

`;