import React from 'react'
import Link from 'gatsby-link'
import './header.css'
import styled from 'styled-components'


const Header = ({ siteTitle }) => (
  <HeaderGroup>
    <LogoTitle to="/" title="To the frontpage">Designmovies</LogoTitle>
    <Submit href="https://forms.gle/v2VEcApxmcYxVoNL8" target="_blank">Submit Video</Submit>
  </HeaderGroup>
)

export default Header

const HeaderGroup = styled.div`
  padding: 0 30px;
  z-index: 100;
  border-bottom: var(--borderSize) solid var(--dark);
  border-top: var(--borderSize) solid var(--dark);
  margin-top: calc( var(--borderSize) - var(--borderSize) - var(--borderSize) );
  height: 104px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light);
  box-shadow: 0px var(--borderSize) 0 rgba(0, 0, 0, .15);
`

const LogoTitle = styled(Link)`
  font-family: Inter Bold;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--dark);

  @media only screen and (max-width: 350px) {
    font-size: 14px;
  }


`

const Submit = styled.a`
  font-family: Inter Bold;
  font-size: 18px;
  text-decoration: none;
  color: var(--dark);
  text-transform: uppercase;

  @media only screen and (max-width: 350px) {
    font-size: 14px;
  }

  :hover {
    text-decoration: underline;
  }

`